html {
  height: 100%;
}

body,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pageHeader {
  padding: 2.5rem 0;
}

.main-content {
  padding: 4rem 0;
}

.code {
  background-color: #eaeaea;
  color: #b80000;
  font-family: monospace, monospace;
}

.hide {
  display: none !important;
}

.header-wrapper {
  background-color: #f7f7f7;
  padding: 20px 0;
  margin-bottom: 30px !important;
}

.fs-small {
  font-size: 0.75rem !important;
}

.fs-medium {
  font-size: 1.00rem !important;
}

.marginTop {
  margin-top: 10px !important;
}

.marginBottom {
  margin-bottom: 10px !important;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.pt-1 {
  padding-top: 1rem;
}

.loader-path {
  fill: none;
  stroke: #28807C;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 10, 10;
}

@keyframes animate-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

.loader-path {
  animation: animate-stroke 1.5s ease-in-out infinite;
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
}

.loader-backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  background: lightgray;
  opacity: 0.6;
}

.circular-loader {
  height: 40px;
  width: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}